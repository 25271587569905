<template>
  <div>
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />

    <v-row justify="start">
      <v-col class="py-0 px-1">
        COMING SOON
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SnackBar } from '@components/App'
import { mapGetters, mapMutations } from 'vuex'
import { MediaURL } from '@components'
import { mongoSelect } from '@utils'
import API from '@api'
import Anima from '@components/Anima'
import MyNotes from '@components/MyNotes'
import Notebook from '@components/Notebook'
import NotesPanel from '@components/Study/NotesPanel'

export default {
  components: {
    SnackBar,
    NotesPanel,
    Anima,
    Notebook,
    MyNotes
  },

  async created() {
    const notebookCount = await API().get(`notebooks/${this.user.id}/count`, {
      params: {
        'sender.profile': this.myProfileId
      }
    })

    if (notebookCount > 0) {
      this.welcome = false
      this.write = false
    }

    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);

    this.people = await API().get(`profiles/${this.user.id}`, {
      params: {
        query: {
          profile: this.myProfileId,
          isValid: true,
          isActive: true,
          _id: {
            $ne: this.myProfileId
          }
        },
        select: mongoSelect.profiles
      }
    })

    this.loading = false
  },

  data: () => ({
    write: true,
    welcome: true,
    notebook: {}
  }),

  computed: {
    ...mapGetters({
      anna: 'anna',
      newUser: 'newUser',
      myProfileId: 'profile/id',
      myName: 'profile/name',
      user: 'user',
      annaChamber: 'annaChamber',
      from: 'from',
      studyTool: 'studyTool'
    }),

    col() {
      const xl = this.$vuetify.breakpoint.xl
      return this.annaChamber ? 100 : xl ? 72 : 70
    },

    // TODO: review this; ideally we will remove as many ad hoc solutions as we can
    padding () {
      return this.$vuetify.breakpoint.lg ? 'pl-0 pr-0' : 'pr-1'
    },

    navColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : '#1a1a1a'
    },

    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    }
  },

  methods: {
    ...mapMutations({
      SET_NOTEBOOKS: 'notebook/notebooks'
    }),
    setNotebook() {}
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 0.5px 0.5px #e9e7911a;
  background:#f8f6927e;
}
.pulse {
  animation: pulse-animation 1.5s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(248, 241, 140, 0.322);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 253, 151, 0.377);
  }
}
</style>